var $ = jQuery.noConflict();

$(window).on('load', function () {
    let method = 'front';
    if ($('body').hasClass('wp-admin'))
        method = 'wp-admin';

    var initializeBlock = function ($block) {

        $('.block-logo-banner__swiper').each(function () {
            var blockId = $(this).closest('.block-logo-banner .container').attr('id');

            let slideCount = $(this).find('.swiper-slide').length;

            let autoplay = $(this).data('autoplay')
            let autoplayTime = $(this).data('autoplay-time') * 1000
            let autoplayOption = {}

            loop = false;
            autoplayOption = {
                delay: 9999999
            }
            if (autoplay == 1 && slideCount > 1) {
                autoplayOption = {
                    delay: autoplayTime,
                    disableOnInteraction: false
                }
                loop = true;
            }

            var swiper_args = {
                slidesPerView: 7,

                loop: loop,
                autoplay: autoplayOption,

                breakpoints: {
                    0: {
                        slidesPerView: 2,
                    },
                    480: {
                        slidesPerView: 3,
                    },
                    767: {
                        slidesPerView: 5,
                    },
                    1260: {
                        slidesPerView: 6,
                    },
                    1366: {
                        slidesPerView: 7,
                    }
                },
                autoHeight: false,
                spaceBetween: 15,
                slideClass: 'swiper-slide',
                navigation: {
                    prevEl: '#' + blockId + ' .btn-circle--prev',
                    nextEl: '#' + blockId + ' .btn-circle--next'
                },
                on: {
                    afterInit: function (swiper) {
                        arrowCheck(swiper);
                    },
                    resize: function (swiper) {
                        arrowCheck(swiper);
                    }

                }
            };

            var swiper_elem = $(this);
            var swiperInit = new Swiper(this, swiper_args);

            function arrowCheck(swiper) {
                let slidesPerView = 6;
                $.each(swiper.passedParams.breakpoints, function (key, value) {
                    if ($(window).width() > key) {
                        slidesPerView = value.slidesPerView;
                    }
                })
                if(slidesPerView >= slideCount){
                    swiper_elem.closest('.block-logo-banner .container').find('.logos-nav').hide();
                }else{
                    swiper_elem.closest('.block-logo-banner .container').find('.logos-nav').show();
                }
            }

            $(this).closest('.block-logo-banner').addClass('load')
        })

    };

    window.onload = function () {
        initializeBlock($(this));
    }();

    if (window.acf) {
        window.acf.addAction('render_block_preview/type=logo-banner', initializeBlock);
    }
});
